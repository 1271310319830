<template>
	<div class="app-container body-tabs-shadow">
		<div class="app-container">
			<div class="row no-gutters h-screen">
				<div class="col-md-12 col-lg-6 col-xl-6 theme-side">
					<div class="p-4 flex flex-col space-y-5">
						<div class="mb-5">
							<div class="flex align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<!-- <div> -->
								<img class="img-fluid md:h-auto logo-top md:pl-20 h-12 logo"
									 src="./../../assets/images/logo/logo.png" alt="logo">
								<!-- </div> -->
								<!-- <div class="font-semibold text-lg md:text-2xl text-white md:tracking-wide whitespac-nowrap ">B2B Portal</div> -->
							</div>
							<div class="flex theme-header-size md:text-xl md:ml-10 text-white mt-4 login-description">
								{{ $t('welcomeEntry') }}
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-12 col-lg-6 col-xl-6">
					<div class="p-4 flex flex-col space-y-2">
						<div class="mb-5">
							<div class="flex md:justify-center align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<div
									class="font-semibold md:ml-16  text-lg theme-header-size text-blue-900 md:tracking-wide">
									{{ $t('enterAccountInfo') }}
								</div>
							</div>
						</div>
						<div class="flex lg:justify-end md:mt-16">
							<div class="login-border-section w-full lg:w-4/5 md:pl-10 md:pr-5 lg:pl-5 lg:pr-5">
								<ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
									<form class="mb-5" @submit.prevent="handleSubmit(processRegister)">
										<!-- <div class="form-group row mb-8">
											<label for="exampleEmail" class="col-sm-4 override-pb override-pt col-form-label label-context -mt-3"></label>
											<div>
												<div class="font-semibold text-2xl link-color whitespace-nowrap">{{ $t('createNew') }}</div><br>
											</div>
										</div> -->

										<!-- <div class="form-group">
											<label for="exampleEmail" class="text-base text-blue-900">{{ $t('username') }}</label>
											<div>
												<ValidationProvider name="username" rules="required|minmax:6,50" v-slot="{ errors }">
													<input v-model="username" name="username" type="text" class="form-control override-form-field-l" required>
													<small class="text-red-500 font-semibold">{{ errors[0] }}</small>
												</ValidationProvider>
											</div>
										</div> -->
										<div class="form-group">
											<label for="first_name" class="text-base font-semibold text-blue-900">{{
													$t('fields.firstName')
												}}</label>
											<div>
												<ValidationProvider name="first_name" rules="required"
																	v-slot="{ errors }">
													<input v-model="first_name" name="first_name" type="text" id="first_name"
														   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
														   required>
													<small class="text-red-500 font-semibold">{{ errors[0] }}</small>
												</ValidationProvider>
											</div>
										</div>
										<div class="form-group">
											<label for="last_name" class="text-base font-semibold text-blue-900">{{
													$t('fields.lastName')
												}}</label>
											<div>
												<ValidationProvider name="last_name" rules="required"
																	v-slot="{ errors }">
													<input v-model="last_name" name="last_name" type="text" id="last_name"
														   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
														   required>
													<small class="text-red-500 font-semibold">{{ errors[0] }}</small>
												</ValidationProvider>
											</div>
										</div>
										<div class="form-group">
											<label for="exampleEmail" class="text-base font-semibold text-blue-900">{{
													$t('email')
												}}</label>
											<div>
												<ValidationProvider name="email" rules="required|email"
																	v-slot="{ errors }">
													<input v-model="email" name="email" type="email"
														   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
														   required>
													<small class="text-red-500 font-semibold">{{ errors[0] }}</small>
												</ValidationProvider>
											</div>
										</div>
										<div class="form-group">
											<label for="exampleEmail" class="text-base font-semibold text-blue-900">{{
													$t('password')
												}}</label>
											<div>
												<ValidationProvider name="password" rules="required|minmax:7,15"
																	v-slot="{ errors }">
													<input v-model="password" name="password" type="password"
														   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
														   required>
													<small class="text-red-500 font-semibold">{{ errors[0] }}</small>
												</ValidationProvider>
											</div>
										</div>
										<div class="form-group">
											<label for="exampleEmail" class="text-base font-semibold text-blue-900">{{
													$t('repeatPassword')
												}}</label>
											<div>
												<ValidationProvider name="repeatPassword" rules="required|minmax:7,15"
																	v-slot="{ errors }">
													<input v-model="confirmPassword" type="password"
														   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
														   required>
													<small class="text-red-500 font-semibold">{{ errors[0] }}</small>
												</ValidationProvider>
											</div>
										</div>
										<!-- <div class="form-group">
											<label for="exampleEmail" class="text-base text-blue-900">{{ $t('setDomain') }}</label>
											<div>
												<ValidationProvider name="domain" rules="required|minmax:4,10" v-slot="{ errors }">
													<input v-model="domain" type="text" class="form-control override-form-field-l" required>
													<small class="text-red-500 font-semibold">{{ errors[0] }}</small>
												</ValidationProvider>
											</div>
										</div> -->
										<div class="form-group">
											<!-- <label class="override-pb override-pt col-form-label label-context"></label> -->
											<div class="md:mt-20">
												<!-- <input v-model="confirmPassword" name="password" type="password" placeholder="Confirm Password" class="form-control override-form-field-l" required> -->
												<button class="w-full login-btn-success px-4 py-2 rounded-md"
														type="submit" :disabled="invalid || processing">
													<span class="text-white" v-if="processing">{{
															$t('creatingAccount')
														}}</span>
													<span class="text-white" v-else>{{ $t('next') }}</span>
												</button>
											</div>
										</div>
										<!-- <div class="form-group">
											<div class="mt-3">
												<span class="font-md-login text-blue-900">{{ $t('customerAlready') }}? <router-link :to="{name : 'login'}" class="no-deco link-color">{{ $t('login') }}</router-link></span>
											</div>
										</div> -->
									</form>
								</ValidationObserver>
							</div>
						</div>
					</div>
					<div class="container text-center md:bottom-0 md:absolute mb-4 flex justify-center">
						<span class="text-blue-900">Copyright © <span>{{ current }} B2B Portal</span></span>
						<span>
							<locale-component :show-text="true" :height="5" :width="5"/>
                        </span>
					</div>
				</div>
			</div>
		</div>
		<Notification/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import {ValidationProvider, ValidationObserver, localeChanged} from 'vee-validate'
import i18n from '@/plugins/i18n'
import LocaleComponent from "../../components/LocaleComponent";

export default {
	name: 'register',
	components: {
		Notification: () => import('./../../customComponent/NotificationAlert'),
		ValidationProvider,
		ValidationObserver,
		LocaleComponent
	},
	data() {
		return {
			current: new Date().getFullYear(),
			baseURL: window.location.origin,
			loaded: false,
			username: '',
			email: '',
			first_name: '',
			last_name: '',
			domain: '',
			password: '',
			confirmPassword: '',
			processing: false,
			accessToken: '',
		}
	},
	computed: {
		...mapGetters({
			// USER_TOKEN : 'USER_TOKEN',
			// GET_USER_UTIL : 'GET_USER_UTIL',
			GET_LOCALE_LANG: 'utility/GET_LOCALE_LANG'
		})
	},
	mounted() {
		// const token = window.localStorage.getItem('entrycom')
		// if (token !== undefined) {
		//     this.accessToken = JSON.parse(token).access_token.access_token
		// }
	},
	beforeDestroy() {

		// window.localStorage.removeItem('entrycom')
	},
	methods: {
		changeLocale(locale) {
			i18n.locale = locale
			this.$store.commit('utility/CHANGE_LOCALE', locale)
			localeChanged()
		},
		processRegister() {
			const storageToken = window.localStorage.getItem('__entryAuth__')
			const selectedSubscription = window.localStorage.getItem('user-selected-subscription')
			if (this.password === '' || this.email === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.password.length > 50 || this.email.length > 50) {
				this.$services.helpers.notification(this.$t('fieldValue'), 'error', this)
			} else if (!this.$services.helpers.validateEmailAddress(this.email)) {
				this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
			} else if (this.password.length < 6) {
				this.$services.helpers.notification(this.$t('passwordGreater'), 'error', this)
			} else if (this.password !== this.confirmPassword) {
				this.$services.helpers.notification(this.$t('passwordNotMatch'), 'error', this)
			} else if (!storageToken) {
				this.$services.helpers.notification(this.$t('invalidUser'), 'error', this)
			} else if (!selectedSubscription) {
				this.$services.helpers.notification(this.$t('noSubscriptionSelected'), 'error', this)
				this.$router.push({'name': 'onboardsubcription'})
			} else {
				this.processing = true
				const userToken = JSON.parse(storageToken)

				const payload = {
					company: {
						// shop_url : this.domain,
						name: userToken.info.Name,
						access_token: userToken.access_token.access_token,
						refresh_token: userToken.access_token.refresh_token,
						expires_in: userToken.access_token.expires_in,
						organization_number: userToken.info.OrganizationNumber,
					},
					user: {
						// username : this.username,
						password: this.password,
						password2: this.confirmPassword,
						email: this.email,
						first_name: this.first_name,
						last_name: this.last_name,
					},
				}

		

				this.$store.dispatch('auth/registerShopOwner', payload)
					.then(res => {
						this.processing = false
			
						const token = window.localStorage.getItem('__entryAuth__')
						window.localStorage.removeItem('__entryAuth__')
						this.createSubscription(selectedSubscription, res.data.company.id)
						// this.$services.helpers.notification(this.$t('accountCreated'), 'success', this)
						// if (token !== undefined) {
						// 	window.localStorage.setItem('firstT', true)
						// 	setTimeout(() => {
						// 		this.$router.push({name: 'onboardsubcription', params: {id: res.data.company.id}})
						// 		window.localStorage.removeItem('__entryAuth__')
						// 	}, 1500)
						// }
					})
					.catch(err => {
				
						this.processing = false
						if (err.response.status === 400) {
							if (err.response.data) {
								this.$services.helpers.notification(this.formatErrorMessage(err.response.data), 'error', this)
							}
						}
						if (err.response.status === 500) {
							this.$services.helpers.notification(this.$t('serverError'), 'error', this)
						}
					})
			}
		},
		createSubscription(subscription_id, companyId) {
			this.$store.dispatch('portal/addUserSubscription', {
				supplier_subscription_type_id: parseInt(subscription_id),
				company_id: companyId
			})
				.then(_ => {
					this.$services.helpers.notification(this.$t('subscriptionSuccessMessage'), 'success', this)
					window.localStorage.removeItem('user-selected-subscription')
					setTimeout(() => {
						this.$router.replace({name: 'login'})
					}, 1500)
				})
				.catch(err => {
					this.processing = false
					this.$services.helpers.notification(err.response.data.message, 'error', this)
				})
		},
		validateCompanyNumber(number) {
			if (!number.includes('-')) {
				return true
			}
			if (number.split('-')[0].length === 6 && number.split('-')[1].length === 4) {
				return false
			}
			return true
		},
		formatErrorMessage(data = {}) {
			if ('user' in data) {
				const user = data.user
				if ('email' in user) {
					return user.email.join('')
				} else {
					return this.$t('invalidCredential')
				}
			} else {
				return this.$t('invalidCredential')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

#app {
	overflow-y: hidden !important;
}

.login-head-text {
	font-size: 1.2rem !important;
}

.login-head-text-xl {
	font-size: 1.5rem !important;
}

.override-form-field {
	border: 0;
	border-bottom: 1px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: unset !important;
	border-radius: unset !important;
}

// .btn-custom {
//     background-color: #F27E44 !important;
//     border-color: #F27E44 !important;
//     color: #FFFFFF;
// }
.override-pt {
	padding-top: 1.2rem !important;
}

.override-pb {
	padding-bottom: unset !important;
}

.overide-width {
	width: 50% !important;
}

.font-md-login {
	font-size: 1rem;
}

.form-group {
	margin-bottom: 0.5rem !important;
}

.form-control {
	// border: 1px solid $form-border !important;
	height: unset !important;
	padding: 0.2rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	// border: 1px solid $theme-primary-color !important;
}

.theme-side {
	background: $production-theme;
}

.login-btn-success {
	background: #E8B105;
}

.logo {
	height: 95px;
}

.login-description {
	position: relative;
	top: 177px;
}

@media only screen and (max-width: 600px) {
	.logo {
		height: 48px;
	}
	.login-description {
		top: 0px
	}
}
</style>
